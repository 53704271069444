.header {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 5px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
  }

.logo {
    padding-left: clamp(15px, 2vw, 60px);
}

.section-title {
    font-size: clamp(20px, 6vw, 50px);
    font-weight: 700;
    padding-bottom: clamp(50px, 6vw, 90px);
}

.description-text {
    font-size: clamp(15px, 2vw, 20px);
    font-weight: 600;
}

.logo-image {
    width: clamp(80px, 8vw, 125px);
    padding-bottom: 10px;
}

.hover {
    cursor: pointer;
}

.menu {
    display: flex;
    padding-right: 20px;
    list-style: none;
}

.hamburger {
    display: none;
    cursor: pointer;
    font-size: 30px;
    color: black;
    width: clamp(50px, 15vw, 75px);
    padding-right: clamp(15px, 2vw, 60px);
}

#toggle-menu {
    display: none;
}

.header-tab {
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    font-weight: 700;
}

.header-tab-label {
    color: black;    
    text-decoration: none;
    font-size: clamp(15px, 1.6vw, 20px);
    position: relative;
}

.header-tab-label::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: black;
    transition: width 0.3s ease;
}

.header-tab-label:hover::after {
    width: 100%;
    left: 0;
    background: black;
}

.cover-picture-wrapper {
    background-color: black;
    position: relative;
}

.cover-picture-styling {
    width: 90%;
    height: auto;
}

.cover-picture-labels {
    height: 70%;
    width: 100%;
    align-content: center;
}

@media (max-width: 1200px) {
    .cover-picture-labels {
        height: 60vh; 
    }
}

@media (max-width: 1100px) {
    .cover-picture-labels {
        height: 50vh; 
    }
}

@media (max-width: 1000px) {
    .cover-picture-labels {
        height: 45vh; 
    }
}

@media (max-width: 900px) {
    .cover-picture-labels {
        height: 40vh; 
    }

    .menu {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: white;
    }

    #toggle-menu:checked + .hamburger + .menu {
        display: flex;
    }

    .hamburger {
        display: block;
    }

    .header-tab {
        padding-top: 10px;
        padding-bottom: 10px;
    }

}

@media (max-width: 800px) {
    .cover-picture-labels {
        height: 37vh; 
    }
}

@media (max-width: 700px) {
    .cover-picture-labels {
        height: 33vh; 
    }
}

@media (max-width: 600px) {
    .cover-picture-labels {
        height: 32vh;
    }

    .footer {
        flex-direction: column;
    }

    .contact-information-wrapper {
        padding-bottom: 20px;
    }

    .contact-information-title {
        text-align: center;
    }

    .contact-information-field {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .cover-picture-labels {
        height: 30vh;
    }
}

.cover-picture-overlay {
    background: rgba(0, 0, 0, .35);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cover-picture-bottom-div {
    padding-top: clamp(20px, 3vw, 90px);
    background-color: #0178FF;  
    border-top-left-radius: 90% 80%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -2px;
}

.cover-picture-title {
    justify-self: center;
    color: white;
    font-size: clamp(16px, 6.6vw, 150px);
    font-weight: 800;
}

.cover-picture-subtext {
    justify-self: center;
    color: white;
    font-size: clamp(17px, 3vw, 50px);
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

.classes-section-wrapper {
    width: 100%;
    background-color: #0178FF;
    overflow: hidden;
    padding-bottom: clamp(60px, 9vw, 70px);
    position: relative;
}

.classes-section-open-for-business-label {
    justify-self: center;
    color: white;
    padding-top: clamp(65px, 9vw, 100px);
    padding-bottom: clamp(20px, 4vw, 50px);
    padding-left: 10px;
    padding-right: 10px;
}

.classes-section-display-classes-wrapper {
    padding-bottom: clamp(20px, 5vw, 50px);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-around;
}

.classes-section-bottom-div {
    padding-top: clamp(20px, 3vw, 90px);
    background-color: #0178FF; 
    border-bottom-right-radius: 80% 90%;
    margin-top: -2px;
}

.classes-wrapper {
    padding-left: 30px;
    padding-right: 30px;
}

@media (max-width: 1000px) {
    .classes-section-display-classes-wrapper {
        flex-direction: column;
        padding-bottom:20px;
    }

    .classes-wrapper {
        padding-bottom: 30px;
    }
    .choreography-section-split-content {
        flex-direction: column;
        padding-left: min(5vw, 50px);
        padding-right: min(5vw, 50px);
        padding-top: 15px;
    }

    .choreography-section-description-wrapper {
        padding-top: clamp(30px, 7vw, 40px);
        padding-bottom: clamp(30px, 6vw, 30px);
    }
}

.classes-wrapper-title-text {
    font-size: clamp(20px, 5.5vw, 30px);
    font-weight: 700;
}

.classes-button {
     padding: clamp(5px, 3vw, 20px);
     border-radius: 10px;
     text-decoration: none;
     color: black;
     background-color: white;
     position: relative;
     border: none;
     transition: all 0.3s ease;
}

.classes-button:hover {
    background-color: black;
    color: white;
}

.classes-button:hover::after {
    width: 100%;
    left: 0;
    background: #3498db;
}

.performances-section-wrapper {
    background-color: white;
    padding-top: 15px;
}

.performances-section-videos-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 70px;
}

.performances-section-title {
    padding-top: 50px;
}

.video-preview {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-bottom: 40px;
    max-width: 90%;
}

.about-us-section-wrapper {
    background-color: black; 
}

.about-us-top-border {
    display: flex;
    background-color: black;
}

.about-us-top-border-circle {
    width: 25%;
    height: clamp(20px, 6.5vw, 80px);
    margin-top: clamp(-40px, 6.5vw, -20px);
    background-color: white;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
}

.about-us-carousel-wrapper {
    padding-top: 75px;
    width: 100vw
}

.carousel-item{
    min-height: clamp(500px, 5vw, 1400px);
}

.about-us-carousel-item-wrapper{
    padding-bottom: 100px;
    padding-left: clamp(40px, 5vw, 300px);
    padding-right: clamp(40px, 5vw, 300px);
}

.about-us-carousel-title {
    color: white;
    padding-bottom: 0;
    padding-top: clamp(10px, 5vw, 50px);
}

.about-us-carousel-name {
    font-weight: 700;
    font-size: clamp(14px, 5vw, 40px);
}

.about-us-carousel-description {
    color: white;
    padding-top: clamp(10px, 5vw, 30px);
}

.carousel-picture {
    width: clamp(200px, 30vw, 400px);
    max-width: 100%;
}

.carousel-profile-picture {
    width: clamp(160px, 30vw, 350px);
    max-width: 100%;
}

.choreography-section-title {
    padding-top: 75px;
}

.choreography-section-split-content {
    padding-bottom: 25px;
    display: flex;
    padding-left: min(5vw, 50px);
    padding-right: min(5vw, 50px);
}

.choreography-section-form {
    display: grid;
    width: 100%;
}

.choreography-section-input {
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-left: 20px;
    border-width: 1px;
}

.choreography-section-input-validation-error {
    width: 80%;
    height: 45px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-left: 20px;
    border-width: 1px;
    color: red;
    border-color: red;
}

.choreography-section-input-description{
    height: 190px;
    white-space: normal;
    padding-top:15px;
}

.choreography-section-input-error {
    color: red;
    text-align: left;
    height: auto;
}

.choreography-section-input-success {
    color: #4BB543;
    text-align: left;
    height: auto;

}

.choreography-section-spinner {
    margin-left: 17%;
}

.choreography-section-button {
    width: clamp(100px, 35%, 350px);
    height: 45px;
    margin-bottom: 20px;
    border-radius: 20px;
    background-color: #0178FF;
    color: white;
    border-width: 0;
    transition: all 0.3s ease;
}

.choreography-section-button:hover {
    background-color: black;
    color: white;
}

.choreography-section-description-wrapper {
    width: 100%;
    align-content: center;
}

.choreography-section-description {
    margin-top: -80px;
    color: black;
    padding-right: min(5vw, 50px);
}

.footer {
    background-color: #0178FF;
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}

.contact-information-wrapper {
    text-align: justify;
}

.contact-information-title {
    color: white;
    font-size: clamp(20px, 4vw, 25px);
    font-weight: 500;
}

.contact-information-field {
    color: white;
    font-size: clamp(9px, 3vw,16px);
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 400;
}

/*Resolution 1440 x 740 */